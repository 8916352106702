import React,{ useState,useEffect} from 'react';
import {  Button, Form, Upload, Col, Row, Tooltip,Divider,Spin,FloatButton } from 'antd';
import { UploadOutlined,
  FileAddOutlined,
  DownloadOutlined,
  DeleteOutlined } from '@ant-design/icons';
import { List, arrayMove, arrayRemove } from 'react-movable';
import {formatName,downloadFile} from "../../../utils/helper"
import { PDFDocument } from 'pdf-lib';
import {Helmet} from "react-helmet";
import './index.css';
import PDFViewer from '../../../components/PdfViewer';
import AppDragger from "../../../components/Dragger"
import { useNotification } from '../../../contexts/NotificationContext';
const Index = () => {
  const [spinning, setSpinning] = useState(false);
  const showNotification = useNotification();
  const [items, setItems] = React.useState([])
  const [pdfFile, setPdfFile] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [form] = Form.useForm();
  useEffect(() => {
    const fetchData = async () => {
      let fileURL = await image2Pdf()
      setPdfFile(fileURL);
    };
    fetchData();
  }, [items]);
  const image2Pdf = async() => {
    const pdfDoc = await PDFDocument.create();
    try {
      for await (let file of items){
        const jpgImageBytes = await file.arrayBuffer()
        const image = (['image/jpeg'].includes(file.type))?await pdfDoc.embedJpg(jpgImageBytes):await pdfDoc.embedPng(jpgImageBytes);
        const page = pdfDoc.addPage()
        const centerX = (page.getWidth() - image.width) / 2;
        const centerY = (page.getHeight() - image.height) / 2;
        // Check if the image fits entirely within the page boundaries
        const fitsWithinPage = image.width <= page.getWidth() && image.height <= page.getHeight();
        // Draw the image on the page if it fits entirely within the page boundaries
        if (fitsWithinPage) {
          page.drawImage(image, {
            x: centerX,
            y: centerY,
            width: image.width,
            height: image.height,
          });
        } else {
          const scaleToFitWidth = page.getWidth() / image.width;
          // Calculate the scaled dimensions
          const scaledWidth = image.width * scaleToFitWidth;
          const scaledHeight = image.height * scaleToFitWidth;
          // Calculate position to center the scaled image in the body of the page
          const centerX = (page.getWidth() - scaledWidth) / 2;
          const centerY = (page.getHeight() - scaledHeight) / 2;
          // Draw the image on the page
          page.drawImage(image, {
            x: centerX,
            y: centerY,
            width: scaledWidth,
            height: scaledHeight,
          });
        }
      }
      // Serialize the PDFDocument to bytes (a Uint8Array)
      const pdfBytest = await pdfDoc.save();
      const pdfBytes = new Uint8Array( pdfBytest ); 
      const file = new Blob([pdfBytes],{type:"application/pdf"});
      const fileURL = URL.createObjectURL(file);
      return fileURL 
    } catch (error) {
      const updatedFiles = items.filter((file, key) => key !== items.length-1);
      setItems(updatedFiles);
      showNotification(
        'error',
        `Fail - ${uploadedFileName}`,
        `Uploaded File ${uploadedFileName} is Damaged or Unreadable.`
      );
    }
  };
  const onDownload = async(docUrl) => {
    setSpinning(true)
    let pdf = await image2Pdf()
    downloadFile(pdf, `${items.length>1?items.length+"-images-convertedToPdf.pdf":formatName(uploadedFileName)}-image-convertedToPdf.pdf`);
    setSpinning(false)
  }
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = await options;
    if(!['image/jpeg','image/png'].includes(file.type)){
      showNotification(
        'error',
        `Fail - ${file.name}`,
        `File format is invalid, File type should be PNG or JPG!`
      );
      return false
    }
    setUploadedFileName(file.name)
    setItems(prevItems => [...prevItems, file]);
  }
  const handleFileButtonClick = () => {
    document.getElementById('fileInput').click();
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.zealerio.com/image-to-pdf"></link>
        <title>Image to PDF with a Free Online PDF Manipulation</title>
        <meta name="description" content="Easily convert image to PDF quickly for free" />
      </Helmet>
      <Spin spinning={spinning} fullscreen />
        { items.length > 0 ? (
        <Form form={form}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Divider plain>Change position of images</Divider>
                <List
                  values={items}
                  onChange={({ oldIndex, newIndex }) =>
                    setItems(arrayMove(items, oldIndex, newIndex))
                  }
                  renderList={({ children, props, isDragged }) => (
                    <ul
                      {...props}
                      style={{
                        padding: '0em 0em 1em 0em',
                        cursor: isDragged ? 'grabbing' : 'inherit'
                      }}>
                      {children}
                    </ul>
                  )}
                  renderItem={({ value, props, index, isDragged, isSelected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        padding: '0.5em',
                        margin: '0.5em 0em',
                        listStyleType: 'none',
                        border: '2px solid #CCC',
                        boxShadow: '3px 3px #AAA',
                        color: '#333',
                        borderRadius: '5px',
                        cursor: isDragged ? 'grabbing' : 'grab',
                        fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                        backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF'
                      }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                        {value.name}
                        <Tooltip title={`Delete ${value.name}`}>
                          <Button shape="circle" icon={<DeleteOutlined />} 
                          onClick={() => {
                            setItems(
                              typeof index !== 'undefined'
                                ? arrayRemove(items, index)
                                : items
                            );
                          }} />
                        </Tooltip>
                      </div>
                    </li>
                  )}
                />
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18} className="gutter-row scrollContent">
              <PDFViewer pdfFile={pdfFile}></PDFViewer>
              <FloatButton.Group shape="circle" style={{ right: 24 }}>
                <FloatButton onClick={()=>{setItems([])}} tooltip={<div>Delete file</div>}  icon={<DeleteOutlined />} />
                <FloatButton onClick={handleFileButtonClick} tooltip={<div>Upload</div>} icon={<FileAddOutlined />}/>
                <FloatButton onClick={onDownload} tooltip={<div>Download</div>} icon={<DownloadOutlined />}/>
              </FloatButton.Group>
            </Col>
          </Row>
        </Form>
        ):(
          <div className="layout">
            <div className="content">
              <div className="centered-container">
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <AppDragger accept=".jpg, .jpeg, .png" multiple={true} customRequest={uploadFile}></AppDragger>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}
      <Upload 
        accept='image/*'
        multiple={true}
        customRequest={uploadFile}
        showUploadList={false}>
        <Button id="fileInput" type="primary" style={{width:"100%",display:"none"}} ghost icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </>
  )
}

export default Index