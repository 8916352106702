import rsa from 'jsrsasign';

export const signToken = (data={timestamp:"key"}) => {
  let key=Math.floor(Date.now() / 1000) + (60 * 60)
  const current = rsa.KJUR.jws.IntDate.get('now');
  const expireTime = current + 6000; // 30 sec
  const header = {alg: 'HS256', typ: 'JWT'};
  var payload = {
    iat: current,
    nbf: current,
    exp: expireTime,
    data,
  };
  let secret = process.env.REACT_APP_JWT_SECRET
  const signature = rsa.KJUR.jws.JWS.sign(
    'HS256', 
    JSON.stringify(header), 
    JSON.stringify(payload), 
    secret);
  return signature
};
  
export const verify = (signature) => {
  let secret = process.env.REACT_APP_JWT_SECRET
  const isValid = rsa.KJUR.jws.JWS.verify(signature, secret, ['HS256']);
  if (!isValid) {
    return new Error('Signature is not valid');
  }
  const header = rsa.KJUR.jws.JWS.readSafeJSONString(rsa.b64utoutf8(signature.split('.')[0]));
  const payload = rsa.jws.JWS.readSafeJSONString(rsa.b64utoutf8(signature.split('.')[1]));
  const current = rsa.KJUR.jws.IntDate.get('now');
  if (current < payload.nbf) {
    return new Error('Signature is not before ' + payload.nbf);
  }
  if (current > payload.exp) {
    return new Error('Signature is expired from ' + payload.exp);
  }
  return payload.data
};

/*
verify(signatureToken)
signToken({a: 'a', b: 1})
*/