import React,{ useEffect,useRef } from 'react';
import { Card, Col, Row } from 'antd';
import {Helmet} from "react-helmet";
import './index.css';

const Index = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.zealerio.com"></link>
        <title>PDF, Image and Document manipulation</title>
        <meta name="description" content="Easily PDF, Image and document modification quickly for free" />
      </Helmet>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card title="PDF manipulation" bordered={true}>
            <ul>
              <li>Read/Edit PDF</li>
              <li>Merge PDF</li>
              <li>Compress/Unlock password PDF</li>
              <li>Image to PDF</li>
              <li>PDF to Image</li>
            </ul>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card title="Image manipulation" bordered={true}>
            <ul>
              <li>View Image</li>
              <li>Crop Image</li>
              <li>Compress Image</li>
              <li>Image converter. Change Image format to JPEG,PNG,BMP,GIF,TIFF. Rotate image. Etc..</li>
            </ul>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card title="Document manipulation" bordered={true}>
            <ul>
              <li>Read/Edit Excel File</li>
              <li>Read Docx, Doc, PDF, PPTX, PPT, XLSX, XLS, CSV, ODT, HTM, HTML, TIFF, TXT, ODT and ODS File</li>
            </ul>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Index