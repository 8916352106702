const getCurrentDateTime=()=> {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const formattedDateTime = `${year}-${month}-${day} ${hours}-${minutes}-${seconds}`;
    return formattedDateTime
}
const formatName=(fileName)=>{
    return `${fileName.replace(/\.[^/.]+$/, "")}`
}
const fileExtension=(fileName)=>{
    return `${fileName.split('.').pop().toLowerCase()}`
}
const downloadFile=(uri,name)=>{
    const link = document.createElement("a");
    link.href = uri;
    link.download = name;
    link.click();
}
const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return bytes + ' Bytes';
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
};
const hikePercentage = (newNum,oldNum) => {
    return Math.round((1-(newNum/oldNum))*100)
}
module.exports = { 
    currentDateTime:getCurrentDateTime(),
    formatName,
    fileExtension,
    downloadFile,
    formatFileSize,
    hikePercentage
}