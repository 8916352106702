import React,{ useState} from 'react';
import {  Button, Form,Spin,Upload,FloatButton, Col,Row,} from 'antd';
import { UploadOutlined,DeleteOutlined,FileAddOutlined,
  DownloadOutlined } from '@ant-design/icons';
import { Spreadsheet } from 'react-spreadsheet';
import * as XLSX from 'xlsx';
import {Helmet} from "react-helmet";
import {formatName,downloadFile,fileExtension} from "../../../utils/helper"
import { useNotification } from '../../../contexts/NotificationContext';
import AppDragger from "../../../components/Dragger"
import './index.css';

const Index = () => {
  const showNotification = useNotification();
  const [spinning, setSpinning] = useState(false);
  const [items, setItems] = React.useState([])
  const [downloadName, setDownloadName] = React.useState()
  const [form] = Form.useForm();
  
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = await options;
    const mimeType = file.type;
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (!file || !file.name.match(/\.(xlsx|xls)$/i)) {
      showNotification(
        'error',
        `Fail - ${fileName}`,
        `File format is invalid, File type should be xlsx or xls!`
      );
      return false
    }
    setDownloadName(fileName)
    setSpinning(true)
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target.result;
        const workbook = XLSX.read(new Uint8Array(buffer), { type: 'array' });
        const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one you want to load
        const sheet = workbook.Sheets[sheetName];
    
        // Get range of occupied cells
        const range = XLSX.utils.decode_range(sheet['!ref']);
    
        // Extract data from each cell
        const sheetData = [];
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const row = [];
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            const cell = sheet[cellRef];
            const cellValue = cell ? cell.v : null; // If cell is null, use null, otherwise use cell value
            row.push({ value: cellValue });
          }
          sheetData.push(row);
        }
        setItems(sheetData);
        setSpinning(false)
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      showNotification(
        'error',
        `Fail - ${downloadName}`,
        `Uploaded File ${downloadName} is Damaged or Unreadable.`
      );
      setSpinning(false)
    }
  }
  const onFinish = async(values) => {
    if(items.length<1){
      showNotification(
        'info',
        `Info - Choose file`,
        `Select excel file!`
      );
      return
    }
    setSpinning(true)
    try {
      let xlsData=[]
      for(let i=0; i<items.length;i++){
        xlsData.push(items[i].map(obj => obj.value))
      }
      const wb = XLSX.utils.book_new();
      // Create a worksheet
      const ws = XLSX.utils.aoa_to_sheet(xlsData);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      // Write the workbook to a binary string
      const wbBinaryString = XLSX.write(wb, { type: 'binary' });
      // Convert the binary string to a Blob
      const wbBlob = new Blob([s2ab(wbBinaryString)], {
        type: 'application/octet-stream',
      });
      let url=URL.createObjectURL(wbBlob)
      let dwnFileName=`${formatName(downloadName)}-modified.${fileExtension(downloadName)}`
      console.log(dwnFileName);
      downloadFile(url, dwnFileName);
      setSpinning(false)
    } catch (error) {
      showNotification(
        'error',
        `Fail - ${downloadName}`,
        `Excel modification fail.`
      );
      setSpinning(false) 
    }
  }
  // Utility function to convert binary string to array buffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  const handleFileButtonClick = () => {
    document.getElementById('fileInput').click();
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.zealerio.com/edit-excel"></link>
        <title>Edit excel with a Free Online Document Manipulation</title>
        <meta name="description" content="Easily read and edit excel online with quickly for free" />
      </Helmet>
     <Spin spinning={spinning} fullscreen />
      {!items.length > 0 ? (
      <div className="layout">
        <div className="content">
          <div className="centered-container">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                <AppDragger accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" multiple={false} customRequest={uploadFile}></AppDragger>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      ):(
      <Form form={form} name="dependencies" layout="vertical">
        <Row gutter={24}>
          <Col className="gutter-row scrollContent" xl={24}>
            <Spreadsheet
              data={items}
              onChange={setItems}
              readOnly={false} // Set to true for view-only mode
            />
            <FloatButton.Group shape="circle" style={{ right: 24 }}>
              <FloatButton onClick={()=>{setItems([])}} tooltip={<div>Delete file</div>}  icon={<DeleteOutlined />} />
              <FloatButton onClick={handleFileButtonClick} tooltip={<div>Upload</div>} icon={<FileAddOutlined />}/>
              <FloatButton onClick={onFinish} tooltip={<div>Download</div>} icon={<DownloadOutlined />}/>
            </FloatButton.Group>
          </Col>
        </Row>
      </Form>
      )} 
      <Upload 
        accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        multiple={true}
        customRequest={uploadFile}
        showUploadList={false}>
        <Button id="fileInput" type="primary" style={{width:"100%",display:"none"}} ghost icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </>
  )
}

export default Index