import React,{ useState } from 'react';
import { Card, Col, Row, Button,Form, Input, Divider, Spin,Alert } from 'antd';
import {Helmet} from "react-helmet";
import {httpRequest} from "../../services/axiosService"
import './index.css';
import { 
    PhoneTwoTone,
    MailTwoTone,
    EnvironmentTwoTone } from '@ant-design/icons';
import rules from '../../utils/rules';

const Index = () => {
    const [form] = Form.useForm();
    const [spinning, setSpinning] = useState(false);
    const [responseApi, setResponseApi] = useState();
    const onSubmit = async(values) =>{
        setSpinning(true)
        const result = await httpRequest.post(
            process.env.REACT_APP_CONTACT_US??"",
            values)
        if(result.statusCode==200){
            setResponseApi(result["body"])
            form.resetFields();
            setSpinning(false)
        }
    }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.zealerio.com"></link>
        <title>Contact Us</title>
        <meta name="description" content="Easily PDF, Image and document modification quickly for free" />
      </Helmet>
      <Spin spinning={spinning} fullscreen />
      <Row gutter={[24]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={{span:12,offset: 4}}>
            <Divider>Send Message</Divider>
        </Col>
        { responseApi ? ( 
        <Col xs={24} sm={24} md={24} lg={16} xl={{span:12,offset: 4}}>
            <Alert
            message="Successfully submitted"
            description={responseApi}
            type="success"
            showIcon/>
        </Col>
        ):(
        <Col xs={24} sm={24} md={24} lg={16} xl={{span:12,offset: 4}}>
            <Form layout="vertical" name="control-hooks" form={form} onFinish={onSubmit}>
                <Row gutter={[24]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item name="name" rules={rules.required}>
                            <Input placeholder="Your Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item name="email" rules={rules.email}>
                            <Input placeholder="Your Email" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item name="subject" rules={rules.required}>
                            <Input placeholder="Subject" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item name="message" rules={rules.required}>
                            <Input.TextArea placeholder='Message' rows={6} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24]}>
                <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form.Item>
                            <Button style={{width:"100%"}} type="primary" ghost htmlType="submit">
                            Send Message
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Col>
        )}
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Row gutter={[24]}>
                <Col style={{marginBottom:"10px"}} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card style={{ width: "100%",textAlign:"center" }}>
                        <i style={{fontSize:"35px"}}><EnvironmentTwoTone /></i>
                        <div>Our Address</div>
                        <div>B104 Adam Street, New York, NY 535022</div>
                    </Card>
                </Col>
                <Col style={{marginBottom:"10px"}} xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Card style={{ width: "100%",textAlign:"center" }}>
                        <i style={{fontSize:"35px"}}><PhoneTwoTone /></i>
                        <div>Call Us</div>
                        <div>+xx xxxx xxxxxx xx</div>
                        {/* <a href="tel:+1234567890">+1 (234) 567-890</a> */}
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Card style={{ width: "100%",textAlign:"center" }}>
                        <i style={{fontSize:"35px"}}><MailTwoTone /></i>
                        <div>Email Us</div>
                        <div><a href="mailto:mail@zealerio.com">mail@zealerio.com</a></div>
                    </Card>
                </Col>
            </Row>  
        </Col>
      </Row>
    </>
  )
}

export default Index