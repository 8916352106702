import React,{ useState} from 'react';
import { Button,List, Form,Image,Upload, Col,Spin,Switch, Radio , Space ,Row,Select,InputNumber,Slider,Divider } from 'antd';
import {
  DownloadOutlined,
  UndoOutlined,
  EyeOutlined,
  DeleteOutlined } from '@ant-design/icons';
import {httpRequest} from "../../../services/axiosService"
import {Helmet} from "react-helmet";
import {formatName,downloadFile} from "../../../utils/helper"
import {useNotification } from '../../../contexts/NotificationContext';
import AppDragger from "../../../components/Dragger"
import CookieConsent, { Cookies } from "react-cookie-consent";
import './index.css';
const { Dragger } = Upload;

const Index = () => {
  const showNotification = useNotification();
  const [spinning, setSpinning] = useState(false);
  
  const [greyscaleSwitch, setGreyscaleSwitch] = React.useState(false)
  const [contrastSwitch, setContrastSwitch] = React.useState(false)
  const [brightnessSwitch, setBrightnessSwitch] = React.useState(false)
  const [opacitySwitch, setOpacitySwitch] = React.useState(false)
  const [rotateSwitch, setRotateSwitch] = React.useState(false)
  const [blurSwitch, setBlurSwitch] = React.useState(false)
  const [flipSwitch, setFlipSwitch] = React.useState(false)
  const [resizeSwitch, setResizeSwitch] = React.useState(false)
  const [resizeWidth, setResizeWidth] = React.useState(false)
  const [resizeHeight, setResizeHeight] = React.useState(false)
  
  const [uploadedImageSize, setUploadedImageSize] = useState(null);
  const [convertedImageSize, setConvertedImageSize] = useState(null);
  const [mimeType, setMimeType] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [base64Image, setBase64Image] = useState(null);
  const [uploadedFile, setUploadedFile] = React.useState(false)
  const [previewFile, setPreviewFile] = React.useState()
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
 
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = await options;
    const mimeType = file.type;
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if(!['image/jpeg','image/png','image/gif','image/svg+xml','image/webp','image/bmp','image/tiff'].includes(file.type)){
      showNotification(
        'error',
        `Fail - ${fileName}`,
        `File format is invalid, File type should be image!`
      );
      return false
    }
    if(['image/svg+xml','image/webp'].includes(file.type)){
      showNotification(
        'error',
        `Fail - ${fileName}`,
        `File format is not supported, SVG and WEBP`
      );
      return false
    }
    setFileName(fileName)
    setMimeType(mimeType)
    const reader = new FileReader();
    reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        setBase64Image(base64String);
        setUploadedFile(true)
    };
    reader.readAsDataURL(file);
    setUploadedImageSize(formatFileSize(file.size))
  }
  const getImageUrl = async()=>{
    try {
      const byteCharacters = atob(previewFile.image);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const base64Data = new Blob([byteArray], { type: previewFile.contentType });
      return URL.createObjectURL(base64Data);
    } catch (error) {
      showNotification(
        'error',
        `Fail - image generation failed`,
        `Uploaded File is Damaged or Unreadable.`
      );
    }
  }
  const onDownload = async() => {
    setSpinning(true)
    let url = await getImageUrl()
    downloadFile(url, `${formatName(fileName)}-converted`);
    setSpinning(false)
  }
  const onPreview = async() => {
    setVisible(true)
  }
  const onFinish = async(values) => {
    values.file=base64Image
    values.fileName=fileName
    setSpinning(true)
    const ajax = await httpRequest.post(process.env.REACT_APP_IMAGE_MANIPULATION??"",values)
    if(ajax.statusCode==200){
      setPreviewFile(ajax["body"])
      const base64Data = ajax["body"]['image'].replace(/^data:image\/(png|jpeg|jpg|gif);base64,/, '');
      const binaryData = atob(base64Data);
      const sizeInBytes = binaryData.length;
      setConvertedImageSize(formatFileSize(sizeInBytes))
    }else{
      console.log(ajax)
      showNotification(
        'error',
        `Fail - image generation failed`,
        `${ajax.msg}`
      );
    }
    setSpinning(false)
  }
  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return bytes + ' Bytes';
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
  };
  const reset = ()=>{
    form.resetFields()
    setUploadedFile(false)
    setPreviewFile("")
  }
  return (
    <>  
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href="https://www.zealerio.com/image-converter"></link>
      <title>Convert image to jpg | png | BMP | TIFF | GIF | SVG | WEBP with a Free Online Image Manipulation</title>
      <meta name="description" content="Easily convert image to any format like jpg, png, bmp, tiff, gif, svg and webp. Make image blur, rotate, opacity, brightness, contrast, greyscale, quality, resize and flip quickly for free" />
    </Helmet>
    <Spin spinning={spinning} fullscreen />
    { uploadedFile ? (
      <Form form={form} layout="vertical" name="control-hooks" onFinish={onFinish} 
        initialValues={{}}>
        <Row gutter={24}>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} xl={{ span: 4, offset: 0 }}>
            <Form.Item style={{fontWeight:"bold"}}>
              Select new format
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} xl={{ span: 4, offset: 0 }}>
            <Form.Item name="format" rules={[{
                required: true,
                message: 'Select format',
              }]}>
              <Select
                placeholder="Compress image type"
                style={{ width: "100%" }}
                options={[
                  {value:"MIME_PNG",label:"PNG"},
                  {value:"MIME_JPEG",label:"JPG"},
                  {value:"MIME_BMP",label:"BMP"},
                  {value:"MIME_TIFF",label:"TIFF"},
                  {value:"MIME_GIF",label:"GIF"},
                  {value:"MIME_SVG",label:"SVG"},
                  {value:"MIME_WEBP",label:"WEBP"}
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} xl={{ span: 4, offset: 0 }}>
            <Form.Item>
              <Button htmlType="submit" style={{width:"100%"}} type="primary">
                Convert Image
              </Button>
            </Form.Item>
          </Col>
          {previewFile &&
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} xl={{ span: 4, offset: 0 }}>
            <Form.Item>
              <Button onClick={onPreview} style={{width:"100%"}} type="primary" ghost icon={<EyeOutlined />}>
                Preview
              </Button>
              {visible &&
                <Image
                  style={{ display: 'none' }}
                  src={`data:${previewFile.contentType};base64,${previewFile.image}`}
                  preview={{
                    visible,
                    src: `data:${previewFile.contentType};base64,${previewFile.image}`,
                    onVisibleChange: (value) => {
                      setVisible(value);
                    },
                  }}
                />
              }
            </Form.Item>
          </Col>
          }
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} xl={{ span: 4, offset: 0 }}>
            <Form.Item>
              <Button onClick={reset} style={{width:"100%"}} type="primary" ghost icon={<UndoOutlined />}>
                Clear
              </Button>
            </Form.Item>
          </Col>
          {previewFile &&
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} xl={{ span: 4, offset: 0 }}>
            <Form.Item>
              <Button onClick={onDownload} style={{width:"100%"}} type="primary" ghost icon={<DownloadOutlined />}>
                Download
              </Button>
            </Form.Item>
          </Col>
          }
          {previewFile && 
          <>
          <Col xs={24} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
          <List
            bordered
            dataSource={[{}]}
            renderItem={(file,index) => {
              return (
                <List.Item>
                  <List.Item.Meta
                  avatar={<Image
                    id='imagePreview'
                    onError={()=>{
                      document.getElementById('imagePreview').getElementsByTagName("img")[0].src="no-preview.png"
                    }}
                    width={50}
                    height={50}
                    src={`data:${previewFile.contentType};base64,${previewFile.image}`}
                  />}
                  title={fileName}
                  description={
                    <>
                    <b>{mimeType} ({uploadedImageSize})</b> Converted to <b>{previewFile.contentType} ({convertedImageSize})</b>
                    </>
                  } />
                  <a onClick={reset}><DeleteOutlined /></a>
                </List.Item>
            )}}
          />
          </Col>
            </>
          }
          <Divider plain>Apply image manipulation filter</Divider>
          <Col xs={24} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
            <Form.Item label="Blur" name="blurSwitch">
              <Switch onChange={(val)=>{
                setBlurSwitch(val)
                form.resetFields(['blur'])
              }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 18, offset: 0 }} md={{ span: 18, offset: 0 }} lg={{ span: 10, offset: 0 }} xl={{ span: 10, offset: 0 }}>
            <Form.Item label="Blur Level" name="blur">
              <Slider
              disabled={blurSwitch?false:true}
              marks={{
                0: '0',
                5: '25',
                10: '50',
                15: '75',
                20: {
                  style: {
                    color: '#f50',
                  },
                  label: <strong>100</strong>,
                },
              }}
              min={0}
              max={20}
              step={1}
            />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
            <Form.Item label="Rotate" name="rotateSwitch">
              <Switch onChange={(val)=>{
                setRotateSwitch(val)
                form.resetFields(['rotate'])
              }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 18, offset: 0 }} md={{ span: 18, offset: 0 }} lg={{ span: 10, offset: 0 }} xl={{ span: 10, offset: 0 }}>
            <Form.Item label="Rotate Level" name="rotate">
              <Slider
              disabled={rotateSwitch?false:true}
              min={0}
              marks={{
                0: '0',
                90: '90',
                180: '180',
                270: '270',
                360: {
                  style: {
                    color: '#f50',
                  },
                  label: <strong>360</strong>,
                },
              }}
              max={360}
              step={1}
            />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
            <Form.Item label="Opacity" name="opacitySwitch">
              <Switch onChange={(val)=>{
                setOpacitySwitch(val)
                form.resetFields(['opacity'])
              }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 18, offset: 0 }} md={{ span: 18, offset: 0 }} lg={{ span: 10, offset: 0 }} xl={{ span: 10, offset: 0 }}>
            <Form.Item label="Opacity Level" name="opacity">
              <Slider
              disabled={opacitySwitch?false:true}
              min={0}
              marks={{
                0: '0',
                0.25: '25',
                0.50: '50',
                0.75: '75',
                1: {
                  style: {
                    color: '#f50',
                  },
                  label: <strong>100</strong>,
                },
              }}
              max={1}
              step={0.01}
            />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
            <Form.Item label="Brightness" name="brightnessSwitch">
              <Switch onChange={(val)=>{
                setBrightnessSwitch(val)
                form.resetFields(['brightness'])
              }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 18, offset: 0 }} md={{ span: 18, offset: 0 }} lg={{ span: 10, offset: 0 }} xl={{ span: 10, offset: 0 }}>
            <Form.Item name="brightness" label="Brightness level">
            <Slider
              disabled={brightnessSwitch?false:true}
              marks={{
                0: '0',
                0.25: '25',
                0.50: '50',
                0.75: '75',
                1: {
                  style: {
                    color: '#f50',
                  },
                  label: <strong>100</strong>,
                },
              }}
              min={0}
              max={1}
              step={0.01}
            />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
            <Form.Item label="Contrast" name="contrastSwitch">
              <Switch onChange={(val)=>{
                setContrastSwitch(val)
                form.resetFields(['contrast'])
              }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 18, offset: 0 }} md={{ span: 18, offset: 0 }} lg={{ span: 10, offset: 0 }} xl={{ span: 10, offset: 0 }}>
            <Form.Item name="contrast" label="Contrast level">
            <Slider
              disabled={contrastSwitch?false:true}
              marks={{
                0: '0',
                0.25: '25',
                0.50: '50',
                0.75: '75',
                1: {
                  style: {
                    color: '#f50',
                  },
                  label: <strong>100</strong>,
                },
              }}
              min={0}
              max={1}
              step={0.01}
            />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
            <Form.Item label="Greyscale" name="greyscale">
              <Switch onChange={(val)=>{
                  setGreyscaleSwitch(val)
                }} />
            </Form.Item>
          </Col> 
          <Col xs={24} sm={{ span: 18, offset: 0 }} md={{ span: 18, offset: 0 }} lg={{ span: 10, offset: 0 }} xl={{ span: 10, offset: 0 }}>
            <Form.Item label="Quality" name="quality">
              <Slider
              marks={{
                0: '0',
                25: '25',
                50: '50',
                75: '75',
                100: {
                  style: {
                    color: '#f50',
                  },
                  label: <strong>100</strong>,
                },
              }}
              min={0}
              max={100}
              defaultValue={100}
              step={1}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
            <Form.Item label="Resize" name="resizeSwitch">
              <Switch onChange={(val)=>{
                setResizeSwitch(val)
                setResizeWidth(val?'fix':'auto')
                setResizeHeight(val?'fix':'auto')
                form.resetFields(['maxWidth'])
                form.resetFields(['maxHeight'])
              }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 9, offset: 0 }} md={{ span: 9, offset: 0 }} lg={{ span: 5, offset: 0 }} xl={{ span: 5, offset: 0 }}>
            <Form.Item label="Max width" name="maxWidth" rules={[
                  () => ({
                    validator() {
                      let value=form.getFieldValue().maxWidth
                      if (resizeWidth!="fix") {
                        return Promise.resolve();
                      }else if(value && value.trim()!="" && (/^\d+$/.test(value))){
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Max width require'));
                    },
                  })
                ]}>
              <Space.Compact>
                <Select disabled={resizeSwitch?false:true} value={resizeWidth=="fix" && resizeSwitch?'fix':'auto'} defaultValue="auto" 
                onChange={(val)=>{
                  setResizeWidth(val)
                  form.resetFields(['maxWidth'])
                }} options={[
                  {
                    value: 'fix',
                    label: 'fix',
                  },
                  {
                    value: 'auto',
                    label: 'auto',
                  }
                ]} />
                <InputNumber disabled={resizeWidth=="fix"?false:true} style={{ width: '100%' }} min={1} stringMode={false}/>
              </Space.Compact>
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 9, offset: 0 }} md={{ span: 9, offset: 0 }} lg={{ span: 5, offset: 0 }} xl={{ span: 5, offset: 0 }}>
            <Form.Item label="Max height" name="maxHeight" rules={[
                  () => ({
                    validator() {
                      let value=form.getFieldValue().maxHeight
                      if (resizeHeight!="fix") {
                        return Promise.resolve();
                      }else if(value && value.trim()!="" && (/^\d+$/.test(value))){
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Max height require'));
                    },
                  })
                ]}>
              <Space.Compact>
                <Select disabled={resizeSwitch?false:true} value={resizeHeight=="fix" && resizeSwitch?'fix':'auto'} defaultValue="auto" 
                onChange={(val)=>{
                  setResizeHeight(val)
                  form.resetFields(['maxHeight'])
                }} options={[
                  {
                    value: 'fix',
                    label: 'fix',
                  },
                  {
                    value: 'auto',
                    label: 'auto',
                  }
                ]} />
                <InputNumber disabled={resizeHeight=="fix"?false:true} style={{ width: '100%' }} min={1} stringMode={false}/>
              </Space.Compact>
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
            <Form.Item label="Flip" name="flipSwitch">
              <Switch onChange={(val)=>{
                setFlipSwitch(val)
                val?form.setFieldsValue({flip:"h"}):form.resetFields(['flip'])
              }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 18, offset: 0 }} md={{ span: 18, offset: 0 }} lg={{ span: 10, offset: 0 }} xl={{ span: 10, offset: 0 }}>
            <Form.Item name="flip" label="Flip direction">
              <Radio.Group name="flipGrop" disabled={flipSwitch?false:true}>
                <Radio value="h">Horizontal</Radio>
                <Radio value="v">Vertical</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ):(
    <div className="layout">
      <div className="content">
        <div className="centered-container">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
              <AppDragger accept='image/*,.webp' multiple={false} customRequest={uploadFile}></AppDragger>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    )}
  { uploadedFile &&
    <CookieConsent
      overlay="true"
      location="bottom"
      buttonText="Accept"
      cookieName="imageConverterCookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={2}>
      We respect your privacy. For your information we are processing your image file at server side after click on <b style={{color:"blue",padding:"5px"}}>Convert Image</b> button. But not storing any information at server side.
    </CookieConsent>
  }
  </>
  )
}

export default Index