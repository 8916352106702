import React,{ useState} from 'react';
import { Button,Form,Image,Col,Spin,List,Row,FloatButton,Upload} from 'antd';
import { 
  UploadOutlined,FileAddOutlined,UndoOutlined,DownloadOutlined,
  EyeOutlined,DeleteOutlined} from '@ant-design/icons';
import {Helmet} from "react-helmet";
import './index.css';
import { useNotification } from '../../../contexts/NotificationContext';
import AppDragger from "../../../components/Dragger"

const Index = () => {
  const showNotification = useNotification();
  const [spinning, setSpinning] = useState(false);
  const [items, setItems] = React.useState([])
  const [previewItems, setPreviewItems] = React.useState([])
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return bytes + ' Bytes';
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
  };
  const onPreview = async() => {
    if(items.length<1){
      showNotification(
        'error',
        `Fail - Choose file`,
        `Image file is required to preview!`
      );
      return false
    }
    setVisible(true)
  }
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = await options;
    if(!['image/jpeg','image/png','image/gif','image/svg+xml','image/webp','image/bmp'].includes(file.type)){
      showNotification(
        'error',
        `Fail - ${file.name}`,
        `File format is invalid, File type should be image!`
      );
      return false
    }
    const blob = new Blob([file],{type:file.type});
    const fileURL = URL.createObjectURL(blob);
    setPreviewItems(prevItems => [...prevItems, fileURL]);
    setItems(prevItems => [...prevItems, file]);
  }
  const deleteItem = (index)=>{
    const itemData = items.filter((item,key) => key !== index);
    const previewData = previewItems.filter((item,key) => key !== index);
    setItems(itemData)
    setPreviewItems(previewData)
  }
  const handleFileButtonClick = () => {
    document.getElementById('fileInput').click();
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.zealerio.com/view-image"></link>
        <title>View image with a Free Online Image Manipulation</title>
        <meta name="description" content="Easily view and preview image online with quickly for free" />
      </Helmet>
      <Spin spinning={spinning} fullscreen />
      {!items.length > 0 &&
      <div className="layout">
        <div className="content">
          <div className="centered-container">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                <AppDragger accept='image/*' multiple={true} customRequest={uploadFile}></AppDragger>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      }
      {items.length > 0 &&
      <>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
            <Form.Item style={{textAlign:'left'}}>
              <List
                bordered
                dataSource={items}
                renderItem={(file,index) => {
                  const blob = new Blob([file],{type:file.type});
                  const fileURL = URL.createObjectURL(blob);
                  return (
                    <List.Item>
                      <List.Item.Meta
                      avatar={<Image
                        width={50}
                        height={50}
                        src={fileURL}
                      />}
                      title={file.name}
                      description={
                        <>
                        Orinal size = {formatFileSize(file.size)} 
                        </>
                      } />
                      <a onClick={() => deleteItem(index)}><DeleteOutlined /></a>
                    </List.Item>
                )}}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 6 }} xl={{ span: 6, offset: 6 }}>
            <Form.Item>
              <Button onClick={()=>{setItems([]); form.resetFields()}} style={{width:"100%"}} type="primary" ghost icon={<UndoOutlined />}>
                Reset
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
            <Form.Item>
              <Button onClick={onPreview} style={{width:"100%"}} type="primary" ghost icon={<EyeOutlined />}>
                Preview
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <FloatButton.Group shape="circle" style={{ right: 24 }}>
          <FloatButton onClick={()=>{setItems([])}} tooltip={<div>Delete file</div>}  icon={<DeleteOutlined />} />
          <FloatButton onClick={handleFileButtonClick} tooltip={<div>Upload</div>} icon={<FileAddOutlined />}/>
          <FloatButton.BackTop visibilityHeight={0} />
        </FloatButton.Group>
      </>
      }
      { visible &&
        <Image.PreviewGroup
          style={{ display: 'none' }}
          preview={{
            visible,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
          items={previewItems}>
          <Image
            style={{ display: 'none' }}
            preview={{
              visible,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
            width={200}
            src="logo192.png"
          />
        </Image.PreviewGroup>
      }
      <Upload 
        accept='image/*'
        multiple={true}
        customRequest={uploadFile}
        showUploadList={false}>
        <Button id="fileInput" type="primary" style={{width:"100%",display:"none"}} ghost icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </>
  )
}

export default Index