import React,{ useState} from 'react';
import {Col,Row,Spin} from 'antd';
import {Helmet} from "react-helmet";

const Index = () => {
  const [spinning, setSpinning] = useState(true);
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href="https://www.zealerio.com/edit-pdf"></link>
      <title>Edit PDFs with a Free Online PDF Editor</title>
      <meta name="description" content="Easily edit PDFs online with annotations in quick steps. Add text, highlight text, comments, draw signature, add image and drawings quickly for free" />
    </Helmet>
    <Row gutter={16}>
      <Spin spinning={spinning} fullscreen />
      <Col span={24} className="gutter-row">
        <iframe
          onLoad={()=>{
            setSpinning(false);
          }}
          title="PDF-editor"
          src="pdfEditor/index.html"
          style={{ width: '100%', minHeight: '75vh', border: 'none' }}
        />
      </Col>
    </Row>
    </>
  )
}

export default Index