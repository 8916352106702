import React,{ useState} from 'react';
import {  Button, Form,Image,Col,Spin,List,Row,Select,InputNumber,Slider} from 'antd';
import {
  DownloadOutlined,
  UndoOutlined,
  EyeOutlined,
  DeleteOutlined} from '@ant-design/icons';
import JSZip from "jszip";
import Compressor from "compressorjs";
import {Helmet} from "react-helmet";
import {downloadFile,fileExtension,formatFileSize,hikePercentage} from "../../../utils/helper"
import { useNotification } from '../../../contexts/NotificationContext';
import AppDragger from "../../../components/Dragger"
import './index.css';

const Index = () => {
  const showNotification = useNotification();
  const [spinning, setSpinning] = useState(false);
  const [items, setItems] = React.useState([])
  const [previewItems, setPreviewItems] = React.useState([])
  const [form] = Form.useForm();
  const zip = new JSZip();
  const onPreview = async() => {
    setPreviewItems([])
    if(items.length<1){
      showNotification(
        'info',
        `Fail - Choose file`,
        `Image file is required to preview!`
      );
      return false
    }
    let compressedFileBlob
    for (const file of items) {
      compressedFileBlob = await compress(
        file, 
        form.getFieldValue().quality/100,form.getFieldValue().maxHeight,
        form.getFieldValue().maxWidth,form.getFieldValue().mimeType
      );
      setPreviewItems(prevItems => [...prevItems, compressedFileBlob]);
    }
    /*
    console.log(items)
    const blob = new Blob([items[0]],{type:"image/jpeg"});
    const fileURL = URL.createObjectURL(blob);
    const { width, height } = await getImageSize(fileURL);
    console.log(`width=${width} height=${height} size=${formatFileSize(blob.size)}`)
    */
  }
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = await options;
    if(!['image/jpeg','image/png','image/gif','image/svg+xml','image/webp','image/bmp'].includes(file.type)){
      showNotification(
        'error',
        `Fail - ${file.name}`,
        `File format is invalid, File type should be image!`
      );
      return false
    }
    setItems(prevItems => [...prevItems, file]);
  }
  const compress = async (file,quality,maxHeight,maxWidth,mimeType,convertSize=5000000) => {
    return await new Promise((resolve, reject) => {
      new Compressor(file, {
        quality,
        maxHeight,
        maxWidth,
        mimeType,
        convertSize,
        success: resolve,
        error: reject,
      });
    });
  };
  const onFinish = async(values) => {
    if(items.length<1) 
    {
      showNotification(
        'info',
        `Fail - Choose file`,
        `Image file is required to preview!`
      );
      return false
    }
    let index=1
    let compressedFileBlob
    let name
    setSpinning(true)
    try {
      for (const file of items) {
        compressedFileBlob = await compress(
          file, 
          values.quality/100,values.maxHeight,
          values.maxWidth,values.mimeType
        );
        name=file.name.replace(/\.[^/.]+$/, "").replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-')
        name=`${name}-compressed.${fileExtension(file.name)}`
        if(items.length>1){
          zip.file(name, compressedFileBlob);
          index=index+1
        }
      }
      if(items.length>1){
        const zipData = await zip.generateAsync({
          type: "blob"
        });
        let url = window.URL.createObjectURL(zipData)
        downloadFile(url,`${items.length}-images-compressed.zip`)
      }else{
        let uri = URL.createObjectURL(compressedFileBlob)
        downloadFile(uri,name)
      }
    } catch (error) {
      showNotification(
        'error',
        `Fail - Compression`,
        `Uploaded image file is Damaged or Unreadable.`
      );
    }
    setSpinning(false)
  }
  const deleteItem = (index)=>{
    const itemData = items.filter((item,key) => key !== index);
    const previewData = previewItems.filter((item,key) => key !== index);
    setItems(itemData)
    setPreviewItems(previewData)
  }
  const reducedSize = (newVal,oldVal)=>{
    let size = hikePercentage(newVal,oldVal)
    if(size>0)
      return `- ${size}`
    return `+ ${Math.abs(size)}`
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.zealerio.com/compress-image"></link>
        <title>Compress image with a Free Online Image Manipulation</title>
        <meta name="description" content="Easily compress image and reduce image size, height, width and quality quickly for free" />
      </Helmet>
      <Spin spinning={spinning} fullscreen />
      { !items.length > 0 ? (
        <div className="layout">
          <div className="content">
            <div className="centered-container">
              <Row gutter={24}>
                <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                  <AppDragger accept='image/*' multiple={true} customRequest={uploadFile}></AppDragger>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      ):(
      <Form form={form} name="dependencies" autoComplete="off"
        layout="vertical"  initialValues={{ quality: 60, mimeType:'image/jpeg',
        maxWidth:'800',maxHeight:'800',resizeType:'px' }} onFinish={onFinish}>
          <Row gutter={24}>
          <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
            <Form.Item>
              <List bordered dataSource={items} renderItem={(file,index) => {
                  const blob = new Blob([file],{type:file.type});
                  const fileURL = URL.createObjectURL(blob);
                  return (
                    <List.Item>
                      <List.Item.Meta
                      avatar={<Image
                        width={50}
                        height={50}
                        src={fileURL}
                      />}
                      title={file.name}
                      description={
                        <>
                        Orinal size = {formatFileSize(file.size)} 
                        {'\u00A0'}{'\u00A0'}
                        {previewItems.length > 0 &&
                          <>
                          <b>Reduced = {formatFileSize(previewItems[index]?.size) } ({reducedSize(previewItems[index]?.size,file.size)}%)</b>
                          </>
                        }
                        </>
                      } />
                      <a onClick={() => deleteItem(index)}><DeleteOutlined /></a>
                    </List.Item>
                )}}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 6 }} xl={{ span: 6, offset: 6 }}>
            <Form.Item label="Compress image type" name="mimeType">
            <Select
              defaultValue={"jpg"}
              placeholder="Compress image type"
              style={{ width: "100%" }}
              options={[{value:"image/png",label:"PNG"},{value:"image/jpeg",label:"JPG"}]}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
            <Form.Item label="Quality" name="quality">
              <Slider marks={{
                0: '0%', 25: '25%',50: '50%',75: '75%',100: '100%'
              }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 6 }} xl={{ span: 6, offset: 6 }}>
            <Form.Item label="Max width" name="maxWidth">
              <InputNumber placeholder="1-65000" min={1} style={{ width: "100%" }} stringMode={false}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
            <Form.Item label="Max height" name="maxHeight">
              <InputNumber placeholder="1-65000" min={1} style={{ width: "100%" }} stringMode={false}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 4, offset: 6 }} xl={{ span: 4, offset: 6 }}>
            <Form.Item>
              <Button onClick={()=>{setItems([]); form.resetFields()}} style={{width:"100%"}} type="primary" ghost icon={<UndoOutlined />}>
                Reset
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 4, offset: 0 }} xl={{ span: 4, offset: 0 }}>
            <Form.Item>
              <Button onClick={onPreview} style={{width:"100%"}} type="primary" ghost icon={<EyeOutlined />}>
                Preview
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 4, offset: 0 }} xl={{ span: 4, offset: 0 }}>
            <Form.Item>
              <Button htmlType="submit" style={{width:"100%"}} type="primary" ghost icon={<DownloadOutlined />}>
                Download
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      )}
    </>
  )
}

export default Index