import React, { useState} from 'react';
import {Link } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BellOutlined 
} from '@ant-design/icons';
import { Layout, Button, theme, Spin,Menu } from 'antd';
import CookieConsent, { Cookies } from "react-cookie-consent";
import "./header.module.css"
const { Header } = Layout;

const Index = () => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  return (
    <>
    <Header style={{ display: 'flex', alignItems: 'center' }}>
      <span style={
          {
            width: '120px',
            minWidth: '120px',
            marginInlineEnd: '24px',
            fontSize:'35px',
            color: 'white',
            textTransform:'uppercase',
          }} className="headerLogo">
          Zealerio
      </span>
      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => {
          setCollapsed(!collapsed)
          dispatch({ type: 'COLLAPSED_MENU', value:!collapsed })
        }}
        style={{fontSize: '16px',width: 64,height: 64,color: 'white'}}
      />
      {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['home']}
        items={[
          {
            key:'home',
            label: <Link to='/'>Dashboard</Link>
          }
        ]}
        style={{ flex: 1, minWidth: 0, float: 'right' }}
      />
      <a title='Notifications' style={{color:'white', fontSize: '16px', width: 0, float: "right" }}>
        <BellOutlined />
      </a> */}
    </Header>
    <CookieConsent
      location="bottom"
      buttonText="Ok"
      cookieName="headerCookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={30}>
      This website uses cookies to ensure you get the best experience on our website.
    </CookieConsent>
    </>
  )
}

export default Index