import React from 'react';
import Routers from './Routers/Routers'
import "./App.css"

function App() {
	return (
		<Routers />
	);
}

export default App;