import {Routes,Route,BrowserRouter as Router, RouterProvider, createBrowserRouter } from 'react-router-dom';
import {CompressImage,Dashboard,PdfToImage,NoMatch,ImageToText,
  CropImage,ExcelDocuments,ReadDocuments,ImageConverter,ContactUs,
  MergePdf,EditPdf,CompressPdf,ImageToPdf,ViewImage,UnlockPdf} from '../pages';
import {PublicRoute} from '../hooks/PublicRoute'
import {PrivateRoute} from '../hooks/PrivateRoute'
import {UserProvider} from "../contexts/UserContext";
import {NotificationProvider} from "../contexts/NotificationContext";
const Routers = () => {
  return (
    <Router>
      <UserProvider>
      <NotificationProvider>
      <Routes>
        <Route path='/' element={<PublicRoute/>}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/edit-pdf" element={<EditPdf />} />
          <Route path="/merge-pdf" element={<MergePdf />} />
          <Route path="/compress-pdf" element={<CompressPdf />} />
          <Route path="/unlock-pdf" element={<UnlockPdf />} />
          <Route path="/image-to-pdf" element={<ImageToPdf />} />
          <Route path="/pdf-to-image" element={<PdfToImage />} />
          <Route path="/compress-image" element={<CompressImage />} />
          <Route path="/crop-image" element={<CropImage />} />
          <Route path="/edit-excel" element={<ExcelDocuments />} />
          <Route path="/read-document" element={<ReadDocuments />} />
          <Route path="/image-converter" element={<ImageConverter />} />
          <Route path="/view-image" element={<ViewImage />} />
          <Route path="/image-to-text" element={<ImageToText />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Route>
        <Route path="*" element={<NoMatch/>} />
      </Routes>
      </NotificationProvider>  
      </UserProvider>
    </Router>
  );
};
  
export default Routers;