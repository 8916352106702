import React from 'react';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const CopyableText = ({ text }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(text)
            .then(() => {
                message.success('Text copied to clipboard!');
            })
            .catch(err => {
                message.error('Failed to copy text.');
                console.error('Copy failed:', err);
            });
    };
    const formatText = (text) => {
        // Decode HTML entities
        const decodedText = decodeHtmlEntities(text);
        // Replace tabs with spaces
        const textWithSpaces = decodedText.replace(/\t/g, '    ');
        // Replace newlines with <br /> tags
        return textWithSpaces.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));
    };
    const decodeHtmlEntities = (text) => {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = text;
        return textarea.value;
    };
    return (
        <div style={{ position: 'relative', padding: '20px', border: '1px solid #d9d9d9', borderRadius: '4px'}}>
            <Button 
                type="text" 
                icon={<CopyOutlined />} 
                style={{ position: 'absolute', top: '10px', right: '10px' }} 
                onClick={handleCopy} 
            />
            <p>{text ? formatText(text) : <>No text extracted</>}</p>
        </div>
    );
};

export default CopyableText;
