import React,{ useState,useEffect} from 'react';
import {  Button, Form, Upload, Col, Row, Spin,Tooltip,FloatButton,Divider } from 'antd';
import { UploadOutlined,DeleteOutlined,FileAddOutlined,DownloadOutlined } from '@ant-design/icons';
import { List, arrayMove, arrayRemove } from 'react-movable';
import { PDFDocument } from 'pdf-lib'
import {downloadFile} from "../../../utils/helper"
//import './index.css';
import PDFViewer from '../../../components/PdfViewer';
import AppDragger from "../../../components/Dragger"
import { useNotification } from '../../../contexts/NotificationContext';
import {Helmet} from "react-helmet";
//const { Dragger } = Upload;
const Index = () => {
  const [spinning, setSpinning] = useState(false);
  const showNotification = useNotification();
  const [items, setItems] = React.useState([])
  const [pdfFile, setPdfFile] = useState("");
  const [form] = Form.useForm();
  useEffect(() => {
    const fetchData = async () => {
      let fileURL = await mergePdf()
      setPdfFile(fileURL);
    };
    fetchData();
  }, [items]);
  const isPdfPasswordProtected=async(file)=>{
    const pdfBytes = await file.arrayBuffer();
      try {
        return await PDFDocument.load(pdfBytes);
      } catch (error) {
        console.log(error)
        showNotification(
          'error',
          `Fail - ${file.name}`,
          `PDF should not be password protected!!`
        );
        const updatedFiles = items.filter((file, key) => key !== items.length-1);
        setItems(updatedFiles);
      }
  }
  const mergePdf = async() => {
    try {
      const mergedPdf = await PDFDocument.create();
      for await (let file of items){
        //const pdfA = await PDFDocument.load(await file.arrayBuffer(),{ ignoreEncryption: true });
        const pdfA = await isPdfPasswordProtected(file)
        if(pdfA){
          const copiedPagesA = await mergedPdf.copyPages(pdfA, pdfA.getPageIndices());
          copiedPagesA.forEach((page) => mergedPdf.addPage(page));
        }
      }
      const mergedPdfFile = await mergedPdf.save();
      const pdfBytes = new Uint8Array( mergedPdfFile ); 
      const file = new Blob( [ pdfBytes ], { type: "application/pdf" } );
      const fileURL = URL.createObjectURL( file );
      return fileURL 
    } catch (error) {
      console.log(error)
      showNotification(
        'error',
        `Fail - merge PDF`,
        `something went wrong with your uploaded PDF`
      );
    } 
  };
  const onDownload = async(docUrl) => {
    if(items.length<2){
      showNotification(
        'info',
        `Info - Choose file`,
        `At least two PDF files are required to merge!`
      );
      return false
    }
    setSpinning(true)
    let url = await mergePdf()
    downloadFile(url, `${items.length+"-pdf-merged.pdf"}`);
    setSpinning(false)
  }
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = await options;
    if(!['application/pdf'].includes(file.type)){
      showNotification(
        'error',
        `Fail - ${file.name}`,
        `File format is invalid, File type should be PDF!`
      );
      return false
    }
    setItems(prevItems => [...prevItems, file]);
  }
  const handleFileButtonClick = () => {
    document.getElementById('fileInput').click();
  }
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href="https://www.zealerio.com/merge-pdf"></link>
      <title>Merge PDFs with a Free Online PDF Manipulation</title>
      <meta name="description" content="Easily merge multiple PDFs online quickly for free" />
    </Helmet>
    <Spin spinning={spinning} fullscreen />
    { items.length > 0 &&
      <Form form={form}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Divider plain>Change position of pdf</Divider>
            <List
              values={items}
              onChange={({ oldIndex, newIndex }) =>
                setItems(arrayMove(items, oldIndex, newIndex))
              }
              renderList={({ children, props, isDragged }) => (
                <ul
                  {...props}
                  style={{
                    padding: '0em 0em 1em 0em',
                    cursor: isDragged ? 'grabbing' : 'inherit'
                  }}>
                  {children}
                </ul>
              )}
              renderItem={({ value, props, index, isDragged, isSelected }) => (
                <li
                  {...props}
                  style={{
                    ...props.style,
                    padding: '0.5em',
                    margin: '0.5em 0em',
                    listStyleType: 'none',
                    border: '2px solid #CCC',
                    boxShadow: '3px 3px #AAA',
                    color: '#333',
                    borderRadius: '5px',
                    cursor: isDragged ? 'grabbing' : 'grab',
                    fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
                    backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF'
                  }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                    {value.name}
                    <Tooltip title={`Delete ${value.name}`}>
                      <Button shape="circle" icon={<DeleteOutlined />} 
                      onClick={() => {
                        setItems(
                          typeof index !== 'undefined'
                            ? arrayRemove(items, index)
                            : items
                        );
                      }} />
                    </Tooltip>
                  </div>
                </li>
              )}
            />
            <FloatButton.Group shape="circle" style={{ right: 24 }}>
                <FloatButton onClick={()=>{setItems([])}} tooltip={<div>Delete file</div>}  icon={<DeleteOutlined />} />
                <FloatButton onClick={handleFileButtonClick} tooltip={<div>Upload</div>} icon={<FileAddOutlined />}/>
                <FloatButton onClick={onDownload} tooltip={<div>Download</div>} icon={<DownloadOutlined />}/>
            </FloatButton.Group>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} className="gutter-row scrollContent">
            <PDFViewer pdfFile={pdfFile}></PDFViewer>
          </Col>
        </Row>
      </Form>
    }
    { !items.length &&
    <div className="layout">
      <div className="content">
        <div className="centered-container">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
              <AppDragger accept=".pdf" multiple={true} customRequest={uploadFile}></AppDragger>
            </Col>
          </Row>
        </div>
      </div>
    </div> 
    }
    <Upload 
      accept=".pdf"
      multiple={true}
      customRequest={uploadFile}
      showUploadList={false}>
      <Button id="fileInput" type="primary" style={{width:"100%",display:"none"}} ghost icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload> 
    </>
  )
}

export default Index