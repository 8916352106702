import React, { useState } from 'react';
import * as pdfjs from 'pdfjs-dist';
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js',
  import.meta.url
).toString()

const PDFViewer = ({pdfFile, onClose, children}) => {
    if (!pdfFile) return null;

    return (
        <>
        <Viewer fileUrl={pdfFile} defaultScale={1}/>
        {/* <iframe id="pdfFrame" src={pdfFile} style={{width: '100%', height: '100%'}}></iframe> */}
        </>
    );
  };
  
export default PDFViewer;