import React, { useState } from 'react';
import { Button, Modal } from 'antd';

const AppModal = ({isOpen, onClose,width=500,children}) => {
    if (!isOpen) return null;
    return (
        <>
        <Modal
            title=""
            centered
            open={isOpen}
            okText="Submit"
            onOk={() => onClose}
            width={width}
            footer={null}
            onCancel={onClose}>
            {children}
        </Modal>
        </>
    );
  };
  
export default AppModal;

{/* <button onClick={openModal}>Open Modal</button>
<PDFViewModal isOpen={isOpen} onClose={closeModal}>
    <Card bordered={false} style={{ overflow: 'auto', maxHeight:"90vh" }}>
        <Worker workerUrl={workerUrl}>
        <Viewer fileUrl={pdfFile} defaultScale={1.5}/>
        </Worker>
    </Card>
</PDFViewModal> */}