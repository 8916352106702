import React,{ useState} from 'react';
import { Button,Form,Image,Col,Spin,List,Row,FloatButton,Upload,Select} from 'antd';
import { 
  UploadOutlined,FileAddOutlined,UndoOutlined,DownloadOutlined,
  EyeOutlined,DeleteOutlined} from '@ant-design/icons';
import {Helmet} from "react-helmet";
import Tesseract from 'tesseract.js';
import './index.css';
import { useNotification } from '../../../contexts/NotificationContext';
import AppDragger from "../../../components/Dragger"
import CopyableText from './copyableText';

const Index = () => {
  const [recognizedText, setRecognizedText] = useState('');
  const showNotification = useNotification();
  const [spinning, setSpinning] = useState(false);
  const [items, setItems] = React.useState(null)
  const [previewItems, setPreviewItems] = React.useState(null)
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const languages = [
    { value: 'eng', label: 'English' },
    { value: 'spa', label: 'Spanish' },
    { value: 'fra', label: 'French' },
    { value: 'deu', label: 'German' },
    { value: 'ita', label: 'Italian' },
    { value: 'por', label: 'Portuguese' },
    { value: 'nld', label: 'Dutch' },
    { value: 'chi_sim', label: 'Chinese (Simplified)' },
    { value: 'chi_tra', label: 'Chinese (Traditional)' },
    { value: 'jpn', label: 'Japanese' },
    { value: 'kor', label: 'Korean' },
    { value: 'rus', label: 'Russian' },
    { value: 'ara', label: 'Arabic' },
    { value: 'hin', label: 'Hindi' },
    { value: 'ell', label: 'Greek' },
    { value: 'tur', label: 'Turkish' },
    { value: 'tha', label: 'Thai' },
    { value: 'vie', label: 'Vietnamese' },
    { value: 'swe', label: 'Swedish' },
    { value: 'dan', label: 'Danish' },
    { value: 'nor', label: 'Norwegian' },
    { value: 'fin', label: 'Finnish' },
    { value: 'pol', label: 'Polish' },
    { value: 'ces', label: 'Czech' },
    { value: 'hun', label: 'Hungarian' },
    { value: 'ron', label: 'Romanian' },
    { value: 'ukr', label: 'Ukrainian' },
    { value: 'heb', label: 'Hebrew' },
    { value: 'msa', label: 'Malay' },
    { value: 'cat', label: 'Catalan' },
    { value: 'bul', label: 'Bulgarian' },
    { value: 'scr', label: 'Serbo-Croatian' },
    { value: 'lav', label: 'Latvian' },
    { value: 'lit', label: 'Lithuanian' },
    { value: 'est', label: 'Estonian' },
    { value: 'slk', label: 'Slovak' },
    { value: 'eus', label: 'Basque' },
    { value: 'glg', label: 'Galician' }
  ];
  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return bytes + ' Bytes';
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
  };
  const onFinish = async(values) => {
    console.log(values)
    if(!items){
      showNotification(
        'error',
        `Fail - Choose file`,
        `Image file is required to preview!`
      );
      return false
    }
    console.log(previewItems)
    setSpinning(true)
    const result = await Tesseract.recognize(previewItems,values.language);
    console.log(result)
    setRecognizedText(result.data.text);
    setSpinning(false)
  }
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = await options;
    if(!['image/jpeg','image/png','image/gif','image/svg+xml','image/webp','image/bmp'].includes(file.type)){
      showNotification(
        'error',
        `Fail - ${file.name}`,
        `File format is invalid, File type should be image!`
      );
      return false
    }
    const blob = new Blob([file],{type:file.type});
    const fileURL = URL.createObjectURL(blob);
    setPreviewItems(fileURL);
    setItems(file);
  }
  const deleteItem = (index)=>{
    setItems(null)
    setPreviewItems(null)
  }
  const handleFileButtonClick = () => {
    document.getElementById('fileInput').click();
  } 
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.zealerio.com/view-image"></link>
        <title>View image with a Free Online Image Manipulation</title>
        <meta name="description" content="Easily view and preview image online with quickly for free" />
      </Helmet>
      <Spin spinning={spinning} fullscreen />
      {!items &&
      <div className="layout">
        <div className="content">
          <div className="centered-container">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                <AppDragger accept='image/*' multiple={true} customRequest={uploadFile}></AppDragger>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      }
      {items &&
      <>
        <Form form={form} name="control-hooks" onFinish={onFinish} 
        initialValues={{}}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
              <Form.Item style={{textAlign:'left'}}>
                <List
                  bordered
                  dataSource={[items]}
                  renderItem={(file,index) => {
                    const blob = new Blob([file],{type:file.type});
                    const fileURL = URL.createObjectURL(blob);
                    return (
                      <List.Item>
                        <List.Item.Meta
                        avatar={<Image
                          width={50}
                          height={50}
                          src={fileURL}
                        />}
                        title={file.name}
                        description={
                          <>
                          Orinal size = {formatFileSize(file.size)} 
                          </>
                        } />
                        <a onClick={() => deleteItem(index)}><DeleteOutlined /></a>
                      </List.Item>
                  )}}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 6 }} xl={{ span: 6, offset: 6 }}>
              <Form.Item style={{fontWeight:"bold"}}>
                Select image text language
              </Form.Item>
            </Col>
            <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
              <Form.Item name="language" rules={[{
                  required: true,
                  message: 'Select image text language',
                }]}>
                <Select
                  placeholder="Select language"
                  style={{ width: "100%" }}
                  options={languages}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 6 }} xl={{ span: 6, offset: 6 }}>
              <Form.Item>
                <Button onClick={()=>{setItems(null); form.resetFields()}} style={{width:"100%"}} type="primary" ghost icon={<UndoOutlined />}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
              <Form.Item>
                <Button htmlType="submit" style={{width:"100%"}} type="primary" ghost icon={<EyeOutlined />}>
                  Extract Text
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
            {/* <div dangerouslySetInnerHTML={{ __html: recognizedText }} /> */}
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <CopyableText text={recognizedText} />
            </div>
          </Col>  
        </Row>
        <FloatButton.Group shape="circle" style={{ right: 24 }}>
          <FloatButton onClick={()=>{setItems(null)}} tooltip={<div>Delete file</div>}  icon={<DeleteOutlined />} />
          <FloatButton onClick={handleFileButtonClick} tooltip={<div>Upload</div>} icon={<FileAddOutlined />}/>
          <FloatButton.BackTop visibilityHeight={0} />
        </FloatButton.Group>
      </>
      }
      { visible &&
        <Image.PreviewGroup
          style={{ display: 'none' }}
          preview={{
            visible,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
          items={[previewItems]}>
          <Image
            style={{ display: 'none' }}
            preview={{
              visible,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
            width={200}
            src="logo192.png"
          />
        </Image.PreviewGroup>
      }
      <Upload 
        accept='image/*'
        multiple={true}
        customRequest={uploadFile}
        showUploadList={false}>
        <Button id="fileInput" type="primary" style={{width:"100%",display:"none"}} ghost icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </>
  )
}

export default Index