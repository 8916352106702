import React, { createContext, useContext, useState } from 'react';
import { notification } from 'antd';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [messageApi, contextHolder] = notification.useNotification();
    const showNotification = (type, message, description, duration = 10,placement="top") => {
        messageApi[type]({
            message: message,
            description: description,
            duration,
            placement
        })
    };
    return (
        <NotificationContext.Provider value={showNotification}>
        {children}
        {contextHolder}
        </NotificationContext.Provider>
    );
};