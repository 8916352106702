import React,{ useState} from 'react';
import {Spin,Button, Select, Upload, Col, Row, Form,FloatButton,Input} from 'antd';
import { UploadOutlined,
  DeleteOutlined, FileAddOutlined,
  DownloadOutlined} from '@ant-design/icons';
import html2canvas from "html2canvas";
import * as pdfjs from 'pdfjs-dist';
import JSZip from "jszip";
import './index.css';
import PDFViewer from '../../../components/PdfViewer';
import { useNotification } from '../../../contexts/NotificationContext';
import {formatName,downloadFile} from "../../../utils/helper"
import AppModal from "../../../components/Modal";
import AppDragger from "../../../components/Dragger"
import {Helmet} from "react-helmet";

const { Option } = Select;
const Index = () => {
  const [attemptCounter, setAttemptCounter] = useState(0)
  const [passwordError, setPasswordError] = useState('');
  const [spinning, setSpinning] = useState(false);
  const showNotification = useNotification();
  const [uploadedFile, setUploadedFile] = useState();
  const [fileName, setFileName] = React.useState()
  const [selectedOption, setSelectedOption] = useState();
  const [pdfFile, setPdfFile] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("");
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const zip = new JSZip();
  const onDownload = async() => {
    setSpinning(true)
    let index=form.getFieldValue()['pageNum']
    let input=document.getElementsByClassName('rpv-core__canvas-layer')
    try {
      if(!index || index=="all"){
        const canvases = [];
        for (let i = 0; i < input.length; i++) {
          const element = document.getElementsByClassName('rpv-core__canvas-layer')[i]
          const canvas = await html2canvas(element);
          canvases.push(canvas);
        }
        canvases.forEach((canvas, index) => {
          const dataUrl = canvas.toDataURL();
          zip.file(`${fileName}-${index+1}.png`, dataUrl.split(';base64,')[1], { base64: true });
        });
        const zipData = await zip.generateAsync({
          type: "blob",
          streamFiles: true,
        });
        let url = window.URL.createObjectURL(zipData)
        downloadFile(url, `${formatName(fileName)}-pdf-convertedToImage.zip`);
        //downloadURI(url,`${fileName}-png.zip`)
      }else{
        const canvas = await html2canvas(input[index-1]);
        let url = canvas.toDataURL();
        downloadFile(url, `${formatName(fileName)}-page-${index}-convertedToImage.png`);
        //downloadURI(url,`${fileName}-page-${index}-png.png`)
      } 
    } catch (error) {
      console.log(error)
      console.log(index)
      showNotification(
        'error',
        `Fail - ${fileName}`,
        `File format is invalid, File type should be PDF!`
      );
    }
    setSpinning(false)
  }
  const onSubmitPassword = async(values)=>{
    setSpinning(true)
    setPassword(values.password)
    await uploadFile(uploadedFile, values.password);
  }
  const uploadFile = async (options,newPassword="") => {
    const { onSuccess, onError, file, onProgress } = await options;
    if(!['application/pdf'].includes(file.type)){
      showNotification(
        'error',
        `Fail - ${file.name}`,
        `File format is invalid, File type should be PDF!`
      );
      return false
    }
    let name=file.name.replace(/\.[^/.]+$/, "").replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-')
    setSpinning(true)
    try {
      setUploadedFile(options)  
      setFileName(name)
      const pdfFile = new Blob( [ file ], { type: "application/pdf" } );
      const fileURL = URL.createObjectURL(pdfFile);
      const pdf = await pdfjs.getDocument(
        {
          url:fileURL,
          password: newPassword
        }).promise;
      setOpenModal(false)
      setSelectedOption([{value:"all",label:"All pages"}])
      for (let i = 0; i < pdf.numPages; i++) {
        setSelectedOption((prevItems) => [...prevItems, {
          value: i+1,
          label: `Page Num ${i+1}`,
        }]);
      }
      /*
      const page = await pdf.getPage(1);
      console.log(page)
      const rawData = await pdf.getData();
      console.log(rawData)
      const blob = new Blob([rawData], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      console.log(blobUrl)
      */
      setPdfFile(fileURL);
    } catch (error) {
      console.log(error)
      if (error.name === 'PasswordException') {
        setSpinning(false)
        if(attemptCounter>0){
          setPasswordError('Password you entered is incorrect');
        }else{
          setPasswordError('PDF is password protected');
        }
        setAttemptCounter(attemptCounter+1)
        setOpenModal(true)
      }else{
        showNotification(
          'error',
          `Fail - ${name}`,
          `${error.name}`
        );
      }
    }
    setSpinning(false)
  }
  const onClose = ()=>{
    setOpenModal(false)
    setAttemptCounter(0)
  }
  const handleFileButtonClick = () => {
    document.getElementById('fileInput').click();
  }
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href="https://www.zealerio.com/pdf-to-image"></link>
      <title>Convert PDF to image with a Free Online PDF Manipulation</title>
      <meta name="description" content="Easily convert pdf to image quickly for free" />
    </Helmet>
    <AppModal isOpen={openModal} onClose={onClose} width={300}>
      <Form layout="vertical" name="document-password" form={passwordForm}
          onFinish={onSubmitPassword}>
        <Form.Item name="password" label="Enter password" 
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError}
          rules={[
            {
              required: true,
              message: 'Please enter your password',
            },
          ]} hasFeedback>
          <Input.Password placeholder="Type your password" />
        </Form.Item>
        <div className="ant-modal-footer semantic-mark-footer">
          <Button type="primary" ghost onClick={onClose}>
            Close
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </AppModal>
    <Spin spinning={spinning} fullscreen />
    {!pdfFile ? (
      <div className="layout">
        <div className="content">
          <div className="centered-container">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                <AppDragger accept='.pdf' multiple={true} customRequest={uploadFile}></AppDragger>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    ):(
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item name="pageNum" label="Select page number">
              <Select
                placeholder="Select page number"
                style={{ width: "100%" }}
                options={selectedOption}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} className="gutter-row scrollContent">
            <PDFViewer pdfFile={pdfFile}></PDFViewer>
            <FloatButton.Group shape="circle" style={{ right: 24 }}>
              <FloatButton onClick={()=>{setPdfFile("")}} tooltip={<div>Delete file</div>}  icon={<DeleteOutlined />} />
              <FloatButton onClick={handleFileButtonClick} tooltip={<div>Upload</div>} icon={<FileAddOutlined />}/>
              <FloatButton onClick={onDownload} tooltip={<div>Download</div>} icon={<DownloadOutlined />}/>
            </FloatButton.Group>
          </Col>
        </Row>
      </Form>
    )} 
    <Upload 
      accept='.pdf'
      multiple={true}
      customRequest={uploadFile}
      showUploadList={false}>
      <Button id="fileInput" type="primary" style={{width:"100%",display:"none"}} ghost icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
    </>
  )
}

export default Index