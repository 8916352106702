import React from 'react';
import { Outlet } from 'react-router-dom';
import {Header,Sidebar} from "../layout"
import {Layout,theme,Breadcrumb} from 'antd';
const { Content } = Layout;

export const PublicRoute = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
      } = theme.useToken();
    return (
        <Layout style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}> 
        {/* maxHeight:'100vh' */}
            <Header/>
            <Layout>
                <Sidebar/>
                <Layout style={{ padding: '0 24px 24px' }}>
                    <Content
                        style={{
                        flex: 1,
                        padding: 24,
                        margin: '16px 0',
                        // overflow: 'hidden',
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                        }}>
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}