import React from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;
const AppDragger = ({ children,multiple,className,customRequest,accept="*" }) => {
  return (
    <Dragger
        accept={accept}
        className={className}
        multiple={multiple}
        customRequest={customRequest}
        showUploadList={false}>
            {children}
        <p className="ant-upload-drag-icon">
            <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            banned files.
        </p>
    </Dragger>
  );
};

export default AppDragger;