import React, { useState } from 'react';
import {useSelector} from 'react-redux';
import {Link,useLocation } from 'react-router-dom';
import {
  HomeOutlined,
  FilePdfOutlined,
  PictureOutlined,
  FileExcelOutlined,
  ReadOutlined,
  MailOutlined ,
  FileOutlined
} from '@ant-design/icons';
import { Layout, Menu,theme  } from 'antd';
const { Sider} = Layout;

const Sidebar = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  //Get collapsed from collapsedReducer
  const collapsed = useSelector((state:any) => state.collapsed.collapsed);
  const location = useLocation();
  return (
    <Sider style={{ background: colorBgContainer }} trigger={null} collapsible collapsed={collapsed}>
      <Menu
        mode="inline"
        defaultSelectedKeys={[location.pathname.slice(1)?location.pathname.slice(1):"dashboard"]}
        style={{ height: '100%', borderRight: 0 }}
        items={[
          {
            key: 'dashboard',
            icon: <HomeOutlined />,
            label: <Link to='/'>Dashboard</Link>
          },
          {
            key: 'pdf',
            icon: <FilePdfOutlined />,
            label: `Pdf`,
            children : [
              {
                key: 'edit-pdf',
                label: <Link to='/edit-pdf'>Edit Pdf</Link>
              },
              {
                key: 'merge-pdf',
                label: <Link to='/merge-pdf'>Merge Pdf</Link>
              },
              {
                key: 'compress-pdf',
                label: <Link to='/compress-pdf'>Compress Pdf</Link>
              },
              {
                key: 'unlock-pdf',
                label: <Link to='/unlock-pdf'>Unlock Pdf</Link>
              },
              {
                key: 'image-to-pdf',
                label: <Link to='/image-to-pdf'>Image to Pdf</Link>
              },
              {
                key: 'pdf-to-image',
                label: <Link to='/pdf-to-image'>Pdf to Image</Link>
              }
            ]
          },
          {
            key: 'image',
            icon: <PictureOutlined />,
            label: `Image`,
            children : [
              {
                key: 'view-image',
                label: <Link to='/view-image'>View Image</Link>
              },
              {
                key: 'crop-image',
                label: <Link to='/crop-image'>Crop Image</Link>
              },
              {
                key: 'compress-image',
                label: <Link to='/compress-image'>Compress Image</Link>
              },
              {
                key: 'image-converter',
                label: <Link to='/image-converter'>Image converter</Link>
              },
              {
                key: 'image-to-text',
                label: <Link to='/image-to-text'>Image to text</Link>
              },
            ]
          },
          {
            key: 'Document',
            icon: <ReadOutlined />,
            label: `Document`,
            children : [
              {
                key: 'edit-excel',
                icon: <FileExcelOutlined />,
                label: <Link to='/edit-excel'>Edit Excel</Link>
              },
              {
                key: 'read-document',
                icon: <FileOutlined />,
                label: <Link to='/read-document'>Read Document</Link>
              }
            ]
          },
          {
            key: 'contactus',
            icon: <MailOutlined  />,
            label: <Link to='/contact-us'>Contact Us</Link>
          }
        ]}
      />
    </Sider>
  )
}

export default Sidebar