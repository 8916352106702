import React,{ useState } from 'react';
import { Layout,Button,Form, Upload, Checkbox, Tooltip,Row,Col,Spin,Input} from 'antd';
import { UndoOutlined ,DownloadOutlined } from '@ant-design/icons';
import * as pdfjs from 'pdfjs-dist';
import {httpRequest} from "../../../services/axiosService"
import {formatName,downloadFile,fileExtension,formatFileSize,hikePercentage} from "../../../utils/helper"
import { useNotification } from '../../../contexts/NotificationContext';
import AppDragger from "../../../components/Dragger"
import './index.css';
import AppModal from "../../../components/Modal";
import {signToken} from  "../../../utils/jsrsasign"
import {Helmet} from "react-helmet";
import CookieConsent, { Cookies } from "react-cookie-consent";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js',
  import.meta.url
).toString()
const { Content } = Layout;
const { Dragger } = Upload;
const Index = () => {
  const [attemptCounter, setAttemptCounter] = useState(0)
  const [openModal, setOpenModal] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [response, setResponse] = useState();
  const [removePswTitle, setRemovePswTitle] = useState("Download PDF with password protection");
  const [spinning, setSpinning] = useState(false);
  const showNotification = useNotification();
  const [passwordError, setPasswordError] = useState('');
  const [form] = Form.useForm();
  
  const convertImageToBase64 = async(file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result.split(',')[1])
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const uploadFile = async (options,password="") => {
    setResponse()
    let isFileProtected=password?true:false
    const { onSuccess, onError, file, onProgress } = await options;
    if(!['application/pdf'].includes(file.type)){
      showNotification(
        'error',
        `Fail - ${file.name}`,
        `File format is invalid, File type should be PDF!`
      );
      return false
    }
    setPdfFile(options);
    const pdfFile = new Blob( [ file ], { type: "application/pdf" } );
    const fileURL = URL.createObjectURL(pdfFile);
    try {
      const pdf = await pdfjs.getDocument(
        {
          url:fileURL,
          password: password
        }).promise;
        setOpenModal(false)
      } catch (error) {
        console.log(error)
      if (error.name === 'PasswordException') {
        isFileProtected=true
        setSpinning(false)
        if(attemptCounter>0){
          setPasswordError('Password you entered is incorrect');
        }else{
          setPasswordError('PDF is password protected');
        }
        setAttemptCounter(attemptCounter+1)
        setOpenModal(true)
      }else{
        showNotification(
          'error',
          `Fail - ${file.name}`,
          `${error.name}`
        );
      }
      return false
    }
    if(!isFileProtected){
      showNotification(
        'error',
        `Fail - ${file.name}`,
        `The pdf file are NOT password protected and aready unlocked`
      );
      return false;
    }
    const base64 = await convertImageToBase64(file);
    setSpinning(true)
    const result = await httpRequest.post(
      process.env.REACT_APP_COMPRESS_PDF??"",
    {
      file:base64,
      password:password?signToken(password):"",
      fileName:file.name,
      ContentType:file.type,
      removePassword:form.getFieldValue().remove_password,
      fileExtension:fileExtension(file.name)
    })
    if(result.statusCode==200){
      setResponse(result["body"]["data"])
    }
    form.resetFields();
    setAttemptCounter(0)
    setSpinning(false)
    
  }
  const onClose = ()=>{
    setOpenModal(false)
    form.resetFields();
    setResponse()
    setAttemptCounter(0)
  }
  const onSubmitPassword = async(values)=>{
    setSpinning(true)
    await uploadFile(pdfFile, values.password);
  }
  const onDownload = async()=>{
    const bufferData = new Uint8Array(response);
    const blob = new Blob([bufferData], { type: 'application/pdf' });
    downloadFile(URL.createObjectURL(blob), `${formatName(pdfFile.file.name)}-unlocked.pdf`);
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.zealerio.com/compress-pdf"></link>
        <title>Remove passwords with a Free Online PDF Manipulation</title>
        <meta name="description" content="Easily compress PDFs and remove password online and reduce size quickly for free" />
      </Helmet>
      <Spin style={{ zIndex: 1001 }} spinning={spinning} fullscreen />
      <AppModal isOpen={openModal} onClose={onClose} width={300}>
        <Form layout="vertical" name="document-password" form={form}
            onFinish={onSubmitPassword} initialValues={{remove_password:true}}>
          <Form.Item name="password" label="Enter password" 
            validateStatus={passwordError ? 'error' : ''}
            help={passwordError}
            rules={[
              {
                required: true,
                message: 'Please enter your password',
              },
            ]} hasFeedback>
            <Input.Password placeholder="Type your password" />
          </Form.Item>
          <Form.Item
            name="remove_password"
            valuePropName="checked">
            <Checkbox onChange={(e)=>{
              if(e.target.checked) {
                setRemovePswTitle("Download PDF without password protection")
              }else{
                setRemovePswTitle("Download PDF with password protection")
              }  
            }}>
              <Tooltip title={removePswTitle}>
                <span>Remove password</span>
              </Tooltip>
            </Checkbox>
          </Form.Item>
          <div className="ant-modal-footer semantic-mark-footer">
            <Button type="primary" ghost onClick={onClose}>
              Close
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </AppModal>
      <div className="layout">
        <div className="content">
          <div className="centered-container">
            <Row gutter={24}>
              { !response ? (
                <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                  <AppDragger accept='.pdf' multiple={false} customRequest={uploadFile}></AppDragger>
                </Col>
              ):(
                <>
                <Col xs={24} sm={{ span: 12, offset: 6 }} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 6 }} xl={{ span: 8, offset: 6 }}>
                  <Form.Item>
                    Locked PDF size
                    <br/>
                    {` ${formatFileSize(pdfFile.file.size)}`}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={{ span: 12, offset: 6 }} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 0 }} xl={{ span: 8, offset: 0 }}>
                  <Form.Item>
                    UnLocked PDF size
                    <br/>
                    {`${formatFileSize(response.length)}`}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={{ span: 12, offset: 6 }} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 6 }} xl={{ span: 8, offset: 6 }}>
                  <Form.Item>
                    <Button onClick={onClose} style={{width:"100%"}} type="primary" ghost icon={<UndoOutlined />}>
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={{ span: 12, offset: 6 }} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 0 }} xl={{ span: 8, offset: 0 }}>
                  <Form.Item>
                    <Button onClick={onDownload} style={{width:"100%"}} type="primary" ghost icon={<DownloadOutlined />}>
                      Download
                    </Button>
                  </Form.Item>
                </Col>
                </>
              )}                  
            </Row>
          </div>
        </div>
      </div>

      <CookieConsent
        overlay="true"
        location="bottom"
        buttonText="Accept"
        cookieName="unlockedPdfCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={2}>
        We respect your privacy. For your information we are processing your pdf file at server side. But not storing any information at server side.
      </CookieConsent>
    </>
  )
}

export default Index