import React,{ useState,useRef } from 'react';
import { Button, Form,Image,Col,Spin,Row,InputNumber,Slider} from 'antd';
import {
  DownloadOutlined,
  UndoOutlined,
  EyeOutlined } from '@ant-design/icons';
import AvatarEditor from 'react-avatar-editor'
import {Helmet} from "react-helmet";
import './index.css';
import {formatName,downloadFile,fileExtension} from "../../../utils/helper"
import { useNotification } from '../../../contexts/NotificationContext';
import AppDragger from "../../../components/Dragger"

const cropConfigDefault={
  height:300,
  width:300,
  borderRadius:50,
  rotate:0,
  scale:1.2
}
const Index = () => {
  const showNotification = useNotification();
  const [spinning, setSpinning] = useState(false);
  const [uploadedFileName, setUploadedFileName] = React.useState()
  const [uploadedFile, setUploadedFile] = React.useState()
  const [previewFile, setPreviewFile] = React.useState()
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [cropConfig, setCropConfig] = React.useState(cropConfigDefault)
  const editor = useRef(null);
  const onPreview = async() => {
    if(!uploadedFile){
      showNotification(
        'info',
        `Fail - Choose file`,
        `Image file is required to preview!`
      );
      return false
    }
    setVisible(true)
    const dataUrl = editor.current.getImage().toDataURL()
    setPreviewFile(dataUrl)
  }
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = await options;
    const fileName = file.name;
    if(!['image/jpeg','image/png','image/gif','image/svg+xml','image/webp','image/bmp'].includes(file.type)){
      showNotification(
        'error',
        `Fail - ${fileName}`,
        `File format is invalid, File type should be image!`
      );
      return false
    }
    setUploadedFileName(fileName)
    const blob = new Blob([file],{type:file.type});
    const fileURL = URL.createObjectURL(blob);
    setUploadedFile(fileURL)
  }
  const onFinish = async(values) => {
    if(!uploadedFile) {
      showNotification(
        'info',
        `Info - Choose file`,
        `Select image file to crop!`
      );
      return false
    }
    // If you want the image resized to the canvas size (also a HTMLCanvasElement)
    //const dataUrl = editor.current.getImageScaledToCanvas().toDataURL()
    setSpinning(true)
    const url = editor.current.getImage().toDataURL()
    downloadFile(url, `${formatName(uploadedFileName)}-cropped.${fileExtension(uploadedFileName)}`);
    setSpinning(false)
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.zealerio.com/crop-image"></link>
        <title>Crop image with a Free Online Image Manipulation</title>
        <meta name="description" content="Easily crop image, rotate image, zoom image and set fixed height and width quickly for free" />
      </Helmet>
      <Spin spinning={spinning} fullscreen />
      <Form form={form} layout="vertical" 
        onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ minHeight: '75vh', textAlign:"center" }}>
          {uploadedFile ? ( 
            <>
            <AvatarEditor
                ref={editor}
                image={uploadedFile}
                width={cropConfig.width}
                height={cropConfig.height}
                border={10}
                borderRadius={cropConfig.borderRadius}
                color={[210, 210, 210, 0.5]} // RGBA
                scale={cropConfig.scale}
                rotate={cropConfig.rotate}
              />
              {visible &&
              <Image
                height={cropConfig.height} 
                width={cropConfig.width}
                style={{ display: 'none' }}
                src={previewFile}
                preview={{
                  visible,
                  src: previewFile,
                  onVisibleChange: (value) => {
                    setVisible(value);
                  },
                }}
              />
              }
            </>
          ):(
            <AppDragger accept='image/*' multiple={false} customRequest={uploadFile}></AppDragger>
          )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={24} xl={12}>
                <Form.Item label="Zoom" name="scale">
                  <Slider
                  min={0.1}
                  max={10}
                  defaultValue={cropConfig.scale}
                  onChange={(value)=>{
                    let size={...cropConfig}
                    size.scale=value
                    setCropConfig(size)
                  }}
                  step={0.01}
                />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={24} xl={12}>
                <Form.Item label="Border radious" name="borderRadius">
                  <Slider
                  min={10}
                  max={1000}
                  defaultValue={cropConfig.borderRadius}
                  onChange={(value)=>{
                    let size={...cropConfig}
                    size.borderRadius=value
                    setCropConfig(size)
                  }}
                  step={1}
                />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={24} xl={12}>
                <Form.Item label="Image rotate" name="rotate">
                  <Slider
                  min={0}
                  max={360}
                  defaultValue={cropConfig.rotate}
                  onChange={(value)=>{
                    let size={...cropConfig}
                    size.rotate=value
                    setCropConfig(size)
                  }}
                  step={1}
                />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={12} xl={6}>
                <Form.Item label="Max width" name="maxWidth">
                  <InputNumber defaultValue={cropConfig.width} onChange={(value)=>{
                    let size={...cropConfig}
                    size.width=value
                    setCropConfig(size)
                  }} min={1} style={{ width: "100%" }} stringMode={false}/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={12} xl={6}>
                <Form.Item label="Max height" name="maxHeight">
                  <InputNumber defaultValue={cropConfig.height} onChange={(value)=>{
                    let size={...cropConfig}
                    size.height=value
                    setCropConfig(size)
                  }} min={1} style={{ width: "100%" }} stringMode={false}/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={12} xl={8}>
                <Form.Item>
                  <Button onClick={()=>{
                    form.resetFields()
                    setCropConfig(cropConfigDefault);
                    setUploadedFile("")
                    }} style={{width:"100%"}} type="primary" ghost icon={<UndoOutlined />}>
                    Reset
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={12} xl={8}>
                <Form.Item>
                  <Button onClick={onPreview} style={{width:"100%"}} type="primary" ghost icon={<EyeOutlined />}>
                    Preview
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={12} xl={8}>
                <Form.Item>
                  <Button htmlType="submit" style={{width:"100%"}} type="primary" ghost icon={<DownloadOutlined />}>
                    Download
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>  
        </Row>
      </Form>
    </>
  )
}

export default Index