import React,{ useState} from 'react';
import {  Button, Form,Upload, Col,Spin, FloatButton,Row} from 'antd';
import { 
  FileAddOutlined,
  DeleteOutlined } from '@ant-design/icons';
import { uuid } from 'unique-string-generator';
import {httpRequest} from "../../../services/axiosService"
import {Helmet} from "react-helmet";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import {fileExtension} from "../../../utils/helper"
import {useNotification } from '../../../contexts/NotificationContext';
import AppDragger from "../../../components/Dragger"
import CookieConsent, { Cookies } from "react-cookie-consent";
import './index.css';
const sampleFile =[{ 
  uri: "https://document-read-public.s3.amazonaws.com/sample1.doc",
  fileType:"docx",
  fileName:"demo.docx"
}]
const Index = () => {
  const showNotification = useNotification();
  const [spinning, setSpinning] = useState(false);
  const [form] = Form.useForm();
  const [fileName, setFileName] = useState(null);
  const [docs, setDocs] = useState([]);

  const convertImageToBase64 = async(file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result.split(',')[1])
        //resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const uploadFile = async (options) => {
    if(fileName){
      await deleteFile()
    }
    const { onSuccess, onError, file, onProgress } = await options;
    if(!['csv','odt','doc','docx','htm','html','pdf','ppt','pptx','tiff','txt','xls','xlsx'].includes(fileExtension(file.name))){
      showNotification(
        'error',
        `Fail - ${file.name}`,
        `Only document file allowed like docx, pdf, csv etc.`
      );
      return false
    }
    const base64 = await convertImageToBase64(file);
    let uploadedFileName=`${uuid.v4()}-${file.name}`
    setSpinning(true)
    const result = await httpRequest.post(
      process.env.REACT_APP_READ_DOCUMENT??"",{
        file:base64,
        fileName:uploadedFileName,
        ContentType:file.type,
        fileExtension:fileExtension(file.name)
      })
    if(result.statusCode==200){
      setDocs(result["body"]);
      setFileName(uploadedFileName)
    }
    setSpinning(false)
  }
  const deleteFile = async()=>{
    setSpinning(true)
    await httpRequest.delete(
      process.env.REACT_APP_DELETE_DOCUMENT??"",{
        fileName:fileName
      })
    setDocs([]);
    setSpinning(false)
  }
  const handleFileButtonClick = () => {
    document.getElementById('fileInput').click();
  }
  return ( 
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href="https://www.zealerio.com/read-document"></link>
      <title>Read document Docx | Doc | PDF | PPTX | PPT | XLSX | XLS | CSV | ODT | HTM | HTML | TIFF | TXT | ODT and ODS File with a Free Online Document Manipulation</title>
      <meta name="description" content="Easily read Docx, Doc, PDF, PPTX, PPT, XLSX, XLS, CSV, ODT, HTM, HTML, TIFF, TXT, ODT and ODS File online with quickly for free" />
    </Helmet>
    <Spin spinning={spinning} fullscreen />
    {docs.length > 0 ? (
      <Form form={form} name="dependencies" layout="vertical">
        <Row gutter={24}>
          <Col xl={24}>
              <DocViewer 
                documents={docs} 
                pluginRenderers={DocViewerRenderers} 
                style={{height:'75vh'}}>
              </DocViewer>
              <FloatButton.Group shape="circle" style={{ right: 24 }}>
                <FloatButton onClick={deleteFile} tooltip={<div>Delete file from server</div>}  icon={<DeleteOutlined />} />
                <FloatButton onClick={handleFileButtonClick} tooltip={<div>Upload</div>} icon={<FileAddOutlined />}/>
                <FloatButton.BackTop visibilityHeight={0} tooltip={<div>BackTop</div>} />
              </FloatButton.Group>
          </Col>
        </Row>
      </Form>
    ):(
      <div className="layout">
        <div className="content">
          <div className="centered-container">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                <AppDragger 
                accept=".csv, .odt, .doc, .docx, .htm, .html, .pdf, .ppt, .pptx, .tiff, .txt, .xls, .xlsx"
                multiple={false} customRequest={uploadFile}></AppDragger>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )}
    <Upload 
      accept=".csv, .odt, .doc, .docx, .htm, .html, .pdf, .ppt, .pptx, .tiff, .txt, .xls, .xlsx"
      multiple={true}
      customRequest={uploadFile}
      showUploadList={false}>
      <Button id="fileInput" type="primary" style={{width:"100%",display:"none"}}>Click to Upload</Button>
    </Upload>
    <CookieConsent
      overlay="true"
      location="bottom"
      buttonText="Accept"
      cookieName="readDocumentCookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={2}>
      We respect your privacy. For your information we are processing your file at server and <b style={{color:"red"}}>storing temporary file into server for 24 hours.</b> We request that you do not upload company data, banned files, or confidential content.
    </CookieConsent>
    </>
  )
}

export default Index