import { message } from 'antd';
// LOGIN
export const Login = (d:any) => {
    if (d) {
        localStorage.setItem('auth', JSON.stringify(d))
        return true
    }else{
        return false
    }
}

// LOGOUT
export const Logout = () => {
    localStorage.removeItem('auth')
    message.success('Logout Success')
    return true
}

// LOGIN STATUS
export const IsLogin = () => {
    if (localStorage.getItem('auth')) return true;
    return false;
}
// Get Login User
export const GetSessionData = () => {
    if(IsLogin()) {
        return JSON.parse(localStorage.getItem('auth')??"")
    }
    return ""
}
export const GetSessionToken = () => {
    if(IsLogin()) {
        return JSON.parse(localStorage.getItem('auth')??"").token
    }
    return ""
}